/**
 * Converts a given string to title case.
 * Title case means the first letter of each word is capitalized. For example, "hello world" becomes "Hello World".
 *
 * @param {string} str - The string to be converted to title case.
 * @returns {string} - The converted string in title case.
 */
export function toTitleCase(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}
