import api from "../api";
import ObjectID from "bson-objectid";
import { toast } from "react-toastify";

import {
  WARRANTY_CREATE_REQUEST,
  WARRANTY_CREATE_SUCCESS,
  WARRANTY_CREATE_FAIL,
  ADMIN_LIST_WARRANTY_REQUEST,
  ADMIN_LIST_WARRANTY_SUCCESS,
  ADMIN_LIST_WARRANTY_FAIL,
  WARRANTY_DETAILS_REQUEST,
  WARRANTY_DETAILS_SUCCESS,
  WARRANTY_DETAILS_FAIL,
  WARRANTY_LIST_MY_FAIL,
  WARRANTY_LIST_MY_SUCCESS,
  WARRANTY_LIST_MY_REQUEST,
  ADMIN_LIST_WARRANTY_UPDATE_REQUEST,
  ADMIN_LIST_WARRANTY_UPDATE_SUCCESS,
  ADMIN_LIST_WARRANTY_UPDATE_FAIL,
  ADMIN_LIST_WARRANTY_UPDATE_RESET,
  WARRANTY_DELETE_REQUEST,
  WARRANTY_DELETE_SUCCESS,
  WARRANTY_DELETE_FAIL,
} from "../constants";

export const createWarranty =
  (warrantyItems, status, comment, orderId) => async (dispatch, getState) => {
    try {
      dispatch({ type: WARRANTY_CREATE_REQUEST });

      const {
        userLogin: { userInfo },
      } = getState();

      const completeWarranty = {
        warrantyId: ObjectID().toString(),
        user: userInfo._id,
        userDetails: {
          userName: `${userInfo.fname} ${userInfo.lname}`,
          userEmail: userInfo.email,
          bc_customer_id: userInfo.bc_customer_id,
          bc_customer_displayName: userInfo.bc_customer_displayName,
          bc_customer_priceGroup: userInfo.bc_customer_priceGroup,
        },
        warrantyItems: warrantyItems,
        warrantyStatus: status,
        warrantyMessage: comment,
        order: orderId,
        itemsCount: warrantyItems.length,
      };

      const res = await api.post(`/api/warranty`, completeWarranty);

      dispatch({
        type: WARRANTY_CREATE_SUCCESS,
        payload: {
          warranty: res.data,
          status: res.status,
          statusText: res.statusText,
        },
      });

      toast.success(`Warranty Request Submitted`, {
        toastId: res.data._id,
      });
    } catch (err) {
      dispatch({ type: WARRANTY_CREATE_FAIL, payload: err.message });
    }
  };

export const updateWarranty =
  (warrantyItems, status, comment, orderId, warranrty) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_LIST_WARRANTY_UPDATE_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();

      const completeWarranty = {
        warrantyId: warranrty.warrantyId,
        _id: warranrty._id,
        user: userInfo._id,
        userDetails: {
          userName: `${userInfo.fname} ${userInfo.lname}`,
          userEmail: userInfo.email,
          bc_customer_id: userInfo.bc_customer_id,
          bc_customer_displayName: userInfo.bc_customer_displayName,
          bc_customer_priceGroup: userInfo.bc_customer_priceGroup,
        },
        warrantyItems: warrantyItems,
        warrantyStatus: status,
        warrantyMessage: comment,
        order: orderId,
        itemsCount: warrantyItems.length,
      };

      const res = await api.patch(`/api/warranty`, completeWarranty);

      dispatch({
        type: ADMIN_LIST_WARRANTY_UPDATE_SUCCESS,
        payload: res.data,
      });

      toast.success(`Warranty updated Successfully`, {
        toastId: res.data._id,
      });
    } catch (err) {
      toast.error(`Warranty Update Failed`);
      dispatch({
        type: ADMIN_LIST_WARRANTY_UPDATE_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const listMyWarranties = (offset) => async (dispatch, getState) => {
  try {
    dispatch({
      type: WARRANTY_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const { data } = await api.get(
      `/api/warranty/mywarranties?offset=${offset}`
    );

    dispatch({
      type: WARRANTY_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: WARRANTY_LIST_MY_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};
