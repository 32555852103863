import api from "../api";
import { toast } from "react-toastify";

// constant imports
import {
  ADMIN_LIST_USERS_REQUEST,
  ADMIN_LIST_USERS_SUCCESS,
  ADMIN_LIST_USERS_FAIL,
  ADMIN_LIST_ORDERS_REQUEST,
  ADMIN_LIST_ORDERS_SUCCESS,
  ADMIN_LIST_ORDERS_FAIL,
  ADMIN_UPDATE_USER_REQUEST,
  ADMIN_UPDATE_USER_SUCCESS,
  ADMIN_UPDATE_USER_FAIL,
  ADMIN_UPDATE_USERS_REQUEST,
  ADMIN_UPDATE_USERS_SUCCESS,
  ADMIN_UPDATE_USERS_FAIL,
  ADMIN_LIST_BCCUSTOMERS_REQUEST,
  ADMIN_LIST_BCCUSTOMERS_SUCCESS,
  ADMIN_LIST_BCCUSTOMERS_FAIL,
  ADMIN_LIST_TICKETS_REQUEST,
  ADMIN_LIST_TICKETS_SUCCESS,
  ADMIN_LIST_TICKETS_FAIL,
  ADMIN_PORTALPRICING_REQUEST,
  ADMIN_PORTALPRICING_SUCCESS,
  ADMIN_PORTALPRICING_FAIL,
  ADMIN_SAVE_PORTALPRICING_REQUEST,
  ADMIN_SAVE_PORTALPRICING_SUCCESS,
  ADMIN_SAVE_PORTALPRICING_FAIL,
  ADMIN_UPDATE_BC_CUSTOMERS_REQUEST,
  ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS,
  ADMIN_UPDATE_BC_CUSTOMERS_FAIL,
  ADMIN_CUSTOMER_TIER_REQUEST,
  ADMIN_CUSTOMER_TIER_SUCCESS,
  ADMIN_CUSTOMER_TIER_FAIL,
  ADMIN_LIST_WARRANTY_REQUEST,
  ADMIN_LIST_WARRANTY_SUCCESS,
  ADMIN_LIST_WARRANTY_FAIL,
} from "../constants";

export const adminUserList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_USERS_REQUEST,
    });

    const { data } = await api.get("/api/admin");

    dispatch({
      type: ADMIN_LIST_USERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_USERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminBcCustomerList = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_BCCUSTOMERS_REQUEST,
    });

    const { data } = await api.get("/api/bc/customers");

    dispatch({
      type: ADMIN_LIST_BCCUSTOMERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_BCCUSTOMERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminUpdateBcCustomers =
  (editedBcCustomers) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_BC_CUSTOMERS_REQUEST,
      });

      const bcCustomersToUpdate = editedBcCustomers.map((bcCustomer) => {
        return {
          bc_customer_id: bcCustomer.bc_customer_id,
          customerTier: bcCustomer.customerTier._id,
        };
      });

      const { data } = await toast.promise(
        api.put("/api/admin/customers", { bcCustomersToUpdate }),
        {
          pending: "BC Customers saving...",
          success: {
            render() {
              return "BC Customers saved.";
            },
          },
          error: "BC Customers not saved.",
        }
      );

      dispatch({
        type: ADMIN_UPDATE_BC_CUSTOMERS_SUCCESS,
        payload: { data },
      });
    } catch (err) {
      dispatch({
        type: ADMIN_UPDATE_BC_CUSTOMERS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminUpdateUsers = (editedUsers) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_UPDATE_USERS_REQUEST,
    });

    const usersToUpdate = editedUsers.map((user) => {
      return {
        _id: user._id,
        bc_customer_id: user.bc_customer_id,
        isApproved: user.isApproved,
        storeAccess: user.storeAccess,
        isAdmin: user.isAdmin,
        isCorporation: user.isCorporation,
      };
    });

    const { data } = await toast.promise(
      api.put("/api/admin", { usersToUpdate }),
      {
        pending: "Users saving...",
        success: {
          render() {
            return "Users saved.";
          },
        },
        error: "Users not saved.",
      }
    );

    dispatch({
      type: ADMIN_UPDATE_USERS_SUCCESS,
      payload: { data },
    });
  } catch (err) {
    dispatch({
      type: ADMIN_UPDATE_USERS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminUpdateUser =
  (_id, bc_customer_id, isApproved, isAdmin) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_UPDATE_USER_REQUEST,
      });

      const {
        adminUserList: { users },
      } = getState();

      const { data } = await toast.promise(
        api.put("/api/admin", { _id, bc_customer_id, isApproved, isAdmin }),
        {
          pending: "User saving...",
          success: {
            render({ data }) {
              return `Portal user: ${data.data.fname} ${data.data.lname} saved.`;
            },
          },
          error: "User not saved.",
        }
      );

      dispatch({
        type: ADMIN_UPDATE_USER_SUCCESS,
        payload: { data, users },
      });
    } catch (err) {
      dispatch({
        type: ADMIN_UPDATE_USER_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminListAllOrders =
  (limit, offset, status, filter) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_LIST_ORDERS_REQUEST,
      });

      const apiUrl = new URL("/api/admin/orders", window.location.origin);
      const params = new URLSearchParams({ limit, offset, filter });

      if (status) {
        params.append("status", status);
      }

      apiUrl.search = params.toString();

      const { data } = await api.get(apiUrl);

      dispatch({
        type: ADMIN_LIST_ORDERS_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADMIN_LIST_ORDERS_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminListAllWarranties =
  (offset) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_LIST_WARRANTY_REQUEST,
      });

      const { data } = await api.get(`/api/admin/warranties?offset=${offset}`);

      dispatch({
        type: ADMIN_LIST_WARRANTY_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: ADMIN_LIST_WARRANTY_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminListAllTickets = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_LIST_TICKETS_REQUEST,
    });

    const { data } = await api.get("/api/admin/tickets");

    dispatch({
      type: ADMIN_LIST_TICKETS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_LIST_TICKETS_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminGetPortalPricing = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_PORTALPRICING_REQUEST,
    });

    const { data } = await api.get("/api/admin/portalpricing");

    dispatch({
      type: ADMIN_PORTALPRICING_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_PORTALPRICING_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminGetCustomerTiers = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADMIN_CUSTOMER_TIER_REQUEST,
    });

    const { data } = await api.get("/api/admin/customertiers");

    dispatch({
      type: ADMIN_CUSTOMER_TIER_SUCCESS,
      payload: data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_CUSTOMER_TIER_FAIL,
      payload:
        err.response && err.response.data.message
          ? err.response.data.message
          : err.message,
    });
  }
};

export const adminSavePortalPricing =
  (pricing) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ADMIN_SAVE_PORTALPRICING_REQUEST,
      });

      const res = await api.post("/api/admin/portalpricing", pricing);

      dispatch({
        type: ADMIN_SAVE_PORTALPRICING_SUCCESS,
        payload: {
          portalPricing: res.data,
          status: res.status,
          statusText: res.statusText,
        },
      });
    } catch (err) {
      dispatch({
        type: ADMIN_SAVE_PORTALPRICING_FAIL,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const adminImportCustomers = () => async (dispatch, getState) => {
  try {
    const { data } = await toast.promise(
      api.get("/api/admin/import/customers"),
      {
        pending: "Importing customers from BC...",
        success: "Customers import successful!",
        error: "Customers import failed.",
      }
    );
  } catch (err) {
    console.log(
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    );
  }
};

export const adminImportItems = () => async (dispatch, getState) => {
  try {
    const { data } = await toast.promise(api.get("/api/admin/import/items"), {
      pending: "Importing items from BC...",
      success: "Items import successful!",
      error: "Items import failed.",
    });
  } catch (err) {
    console.log(
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    );
  }
};

export const adminImportPrices = () => async (dispatch, getState) => {
  try {
    const { data } = await toast.promise(api.get("/api/admin/import/prices"), {
      pending: "Importing prices from BC...",
      success: "Prices import successful!",
      error: "Prices import failed.",
    });
  } catch (err) {
    console.log(
      err.response && err.response.data.message
        ? err.response.data.message
        : err.message
    );
  }
};
