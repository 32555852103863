import api from "../api";
import {
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAILURE,
  FILE_LIST_REQUEST,
  FILE_LIST_SUCCESS,
  FILE_LIST_FAILURE,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_FAILURE,
} from "../constants";

export const uploadFiles =
  (bucket, dealerName, orderId, files) => async (dispatch, getState) => {
    try {
      dispatch({ type: FILE_UPLOAD_REQUEST });

      const formData = new FormData();

      files.forEach((file) => {
        formData.append("files", file);
      });

      let apiUrl = `/api/files/${bucket}`;

      if (dealerName) {
        apiUrl += `/${dealerName}`;
        if (orderId) {
          apiUrl += `/${orderId}`;
        }
      }

      const { data } = await api.post(apiUrl, formData, {
        "Content-Type": "multipart/form-data",
      });

      const { uploadedFiles } = data;

      dispatch({
        type: FILE_UPLOAD_SUCCESS,
        payload: { bucket, uploadedFiles },
      });
    } catch (error) {
      const errorMessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({ type: FILE_UPLOAD_FAILURE, payload: errorMessage });
    }
  };

export const listFiles =
  (bucket, dealerName, orderId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FILE_LIST_REQUEST,
      });

      let apiUrl = `/api/files/${bucket}`;

      if (dealerName) {
        apiUrl += `/${dealerName}`;
        if (orderId) {
          apiUrl += `/${orderId}`;
        }
      }

      const { data } = await api.get(apiUrl);

      dispatch({
        type: FILE_LIST_SUCCESS,
        payload: data,
      });
    } catch (err) {
      dispatch({
        type: FILE_LIST_FAILURE,
        payload:
          err.response && err.response.data.message
            ? err.response.data.message
            : err.message,
      });
    }
  };

export const listFilesForOrder = (dealerName, orderId) => async (dispatch) => {
  try {
    dispatch({ type: FILE_LIST_REQUEST });

    const response = await api.get("/list", {
      params: {
        dealerName,
        orderId,
      },
    });

    dispatch({ type: FILE_LIST_SUCCESS, payload: response.data });
  } catch (error) {
    dispatch({ type: FILE_LIST_FAILURE, payload: error.message });
  }
};

export const deleteFile =
  (bucket, dealerName, orderId, fileName) => async (dispatch, getState) => {
    try {
      dispatch({ type: FILE_DELETE_REQUEST });

      const res = await api.delete(
        `/api/files/${bucket}/${dealerName}/${orderId}/${fileName}`
      );

      const { file } = res;

      dispatch({ type: FILE_DELETE_SUCCESS, payload: { bucket, file } });
    } catch (error) {
      dispatch({ type: FILE_DELETE_FAILURE, payload: error.message });
    }
  };
