import axios from "axios";

const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? "" // relative URL in production, same host that serves the app
    : (process.env.REACT_APP_API_BASE_URL || "http://localhost:5000");

const api = axios.create({
  baseURL: API_BASE_URL,
  timeout: 20000,
  headers: {
    "Content-Type": "application/json",
  },
});

// We add a request interceptor to attach the token automatically
api.interceptors.request.use(
  (config) => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || null;

    if (userInfo && userInfo.token) {
      config.headers.Authorization = `Bearer ${userInfo.token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
