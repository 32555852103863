const { MATERIAL_SENDER_LOCATIONS } = require("./constants");

/**
 * From a sender **found in** MATERIAL_SENDER_LOCATIONS (to a receiver at any
 * other location), it is a material request
 * @param {*} sender
 * @returns
 */
export const isOrderMaterialRequest = (sender) => {
  return MATERIAL_SENDER_LOCATIONS.map((location) =>
    location.toLowerCase()
  ).includes(sender.toLowerCase());
};

/**
 * From a sender **not found** in MATERIAL_SENDER_LOCATIONS (to a receiver at
 * any other location), it is a material transfer
 * @param {*} sender
 * @returns
 */
export const isOrderMaterialTransfer = (sender) => {
  return !MATERIAL_SENDER_LOCATIONS.map((location) =>
    location.toLowerCase()
  ).includes(sender.toLowerCase());
};
