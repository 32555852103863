import React, { useContext, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import { StoreContext } from "../../../context";
import { adminBcCustomerList, createOrder } from "../../../actions";
import { Loader } from "../../Loader";
import { Message } from "../../Message";

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Cancel } from "@material-ui/icons";

import { isOrderMaterialRequest, isOrderMaterialTransfer } from "../helpers";
import { SUNCOAST_BUILT_CDN, SUNCOAST_BUILT_USA } from "../constants";

/**
 * @typedef {import('../../types/orderTypes.js').Order} Order
 */

const useStyles = makeStyles((theme) => ({
  cancelBtn: {
    marginRight: "auto",
  },
  dialogBtn: {
    marginLeft: "auto",
    marginRight: 5,
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.secondary.main,
  },
}));

const RowBox = withStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}))(Box);

const schema = z
  .object({
    orderName: z
      .string()
      .min(3, {
        message: "Order Name is required (at least 3 characters long)",
      })
      .max(50, { message: "Order Name is too long (50 characters max)" }),
    orderNumber: z.string().optional(),
    isMaterialRequestOrTransfer: z.boolean(),
    sender: z.string().nullable().optional(),
    receiver: z.string().nullable().optional(),
  })
  .refine(
    (data) => {
      // orderNumber is required when isMaterialRequestOrTransfer is false
      if (!data.isMaterialRequestOrTransfer && !data.orderNumber) {
        return false;
      }
      return true;
    },
    {
      message: "Order Number is required",
      path: ["orderNumber"],
    }
  )
  .refine(
    (data) => {
      if (!data.isMaterialRequestOrTransfer && data.orderNumber) {
        return data.orderNumber.length >= 3;
      }
      return true;
    },
    {
      message: "Order Number needs to be at least 3 digits long",
      path: ["orderNumber"],
    }
  )
  .refine(
    (data) => {
      if (data.isMaterialRequestOrTransfer) {
        return !!data.sender;
      }
      return true;
    },
    {
      message: "Required for Material Request / Transfer",
      path: ["sender"],
    }
  )
  .refine(
    (data) => {
      if (data.isMaterialRequestOrTransfer) {
        return !!data.receiver;
      }
      return true;
    },
    {
      message: "Required for Material Request / Transfer",
      path: ["receiver"],
    }
  )
  .refine((data) => data.receiver !== data.sender, {
    message: "Sender and Receiver must be different",
    path: ["receiver"],
  });

/**
 * OrderConfirmationDialog component for creating or editing an order. Used by both for customers and admins.
 *
 * @param {Object} props - The component props.
 * @param {string} props.dialogTitle - The title of the dialog.
 * @param {string} props.extraDetails - Additional details for the order.
 * @param {string} props.saveButtonLabel - Label for the *save* form button.
 * @param {string} props.submitButtonLabel - Label for the *submit* form button.
 * @param {string} props.openDialogButtonLabel - Label for the button that triggers the dialog.
 * @returns {JSX.Element} The rendered component.
 */
function OrderConfirmationDialog(props) {
  const {
    dialogTitle,
    extraDetails,
    saveButtonLabel,
    submitButtonLabel,
    openDialogButtonLabel,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const ctx = useContext(StoreContext);
  const { cartInfo } = ctx;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const userBcPriceGroup = userInfo?.bc_customer_priceGroup;

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    dispatch(adminBcCustomerList());
  }, [dispatch]);

  const adminData = useSelector((state) => state.adminBcCustomerList);
  const { loading, error, bcCustomers } = adminData;

  const corporateSenders = useMemo(() => {
    if (!Array.isArray(bcCustomers)) return [];

    const excludeSenders = ["Suncoast Australia"];

    if (userBcPriceGroup.startsWith("USD")) {
      excludeSenders.push(SUNCOAST_BUILT_CDN);
    } else if (userBcPriceGroup.startsWith("CAD")) {
      excludeSenders.push(SUNCOAST_BUILT_USA);
    }

    return bcCustomers
      ?.filter(
        (c) =>
          c.bc_customer_displayName.toLowerCase().startsWith("suncoast") &&
          excludeSenders.includes(c.bc_customer_displayName) === false
      )
      .map((c) => c.bc_customer_displayName);
  }, [bcCustomers, userBcPriceGroup]);

  const corporateReceivers = useMemo(
    () =>
      corporateSenders?.filter(
        (c) => !c.toLowerCase().startsWith("suncoast built")
      ),
    [corporateSenders]
  );

  const defaultSender = corporateSenders?.find((sender) => {
    if (userBcPriceGroup.startsWith("USD")) {
      return sender === SUNCOAST_BUILT_USA;
    } else if (userBcPriceGroup.startsWith("CAD")) {
      return sender === SUNCOAST_BUILT_CDN;
    }

    return false;
  });

  const methods = useForm({
    defaultValues: {
      orderName: "",
      orderNumber: "",
      isMaterialRequestOrTransfer: false,
      sender: defaultSender,
      receiver: null,
    },
    resolver: zodResolver(schema),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    watch,
  } = methods;
  const formValues = watch();
  const { isMaterialRequestOrTransfer } = formValues;

  const materialRequestOrTransferLabel = useMemo(() => {
    if (!formValues.sender && !formValues.receiver) {
      return "Material Request / Transfer";
    }

    if (isMaterialRequestOrTransfer) {
      return isOrderMaterialRequest(formValues.sender)
        ? "Material Request"
        : "Material Transfer";
    }

    return "Material Request / Transfer";
  }, [formValues.receiver, formValues.sender, isMaterialRequestOrTransfer]);

  useEffect(() => {
    if (ctx.isOrder) {
      setIsEditing(true);

      reset({
        isMaterialRequestOrTransfer: cartInfo.transfer,
        orderName: cartInfo.orderName,
        orderNumber: cartInfo.orderNumber,
        receiver: cartInfo.receiver,
        sender: cartInfo.sender,
      });
    }
  }, [ctx.isOrder, cartInfo, reset, corporateSenders]);

  useEffect(() => {});

  const shouldShowOrderNumber =
    userInfo.isCorporation &&
    (cartInfo.screens.length > 0 ||
      cartInfo.roofs.length > 0 ||
      !isMaterialRequestOrTransfer);

  const dropDiffs = () => {
    const { screens } = cartInfo;
    const diffs = screens.filter(
      (e) => e.measurements.dropLeftDisplay !== e.measurements.dropRightDisplay
    );
    return diffs;
  };

  const handleCancelSubmit = () => {
    reset();
    setDialogIsOpen(false);
  };

  const submitOrder = (data) => {
    const status =
      isEditing && ctx?.isAdmin && cartInfo?.status !== "Draft"
        ? "Approved"
        : "Submitted";

    // NOTE: this was commented out in the admin usage of approving orders
    // dispatch(bcSalesQuote({ ...cartInfo, orderName: orderName }));

    dispatch(
      createOrder(cartInfo, {
        extraDetails,
        orderName: data.orderName,
        orderNumber: data.orderNumber,
        receiver: data.receiver,
        sender: data.sender,
        status,
        transfer: data.isMaterialRequestOrTransfer,
      })
    );
  };

  const saveOrder = (data) => {
    const status =
      isEditing && ctx.isAdmin && cartInfo.status !== "Draft"
        ? "Submitted"
        : "Draft";

    dispatch(
      createOrder(cartInfo, {
        extraDetails,
        orderName: data.orderName,
        orderNumber: data.orderNumber,
        receiver: data.receiver,
        sender: data.sender,
        status,
        transfer: data.isMaterialRequestOrTransfer,
      })
    );
  };

  const onSubmitForm = handleSubmit(
    (data, event) => {
      const submitType = event?.nativeEvent?.submitter?.name;

      if (submitType === "saveOrder") {
        saveOrder(data);
      } else if (submitType === "submitOrder") {
        submitOrder(data);
      }

      setDialogIsOpen(false);
    },
    (errors) => {
      console.log("Form errors:", errors);
    }
  );

  const shouldAllowMaterialTransfer =
    userInfo.isCorporation &&
    !cartInfo.screens.length &&
    !cartInfo.roofs.length;

  const dropDifferences = dropDiffs()?.length > 0 ? dropDiffs() : [];
  const allowedStatues = ["Draft", "In Cart", "Submitted"];
  const canView = allowedStatues.includes(cartInfo?.status);
  const canEdit = allowedStatues.includes(cartInfo?.status);

  return (
    <>
      {canView && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => setDialogIsOpen(true)}
          className={classes.dialogBtn}
        >
          {openDialogButtonLabel}
        </Button>
      )}

      <Dialog
        open={dialogIsOpen}
        onClose={() => setDialogIsOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          {dialogTitle}
          <IconButton
            onClick={() => setDialogIsOpen(false)}
            className={classes.closeBtn}
          >
            <Cancel />
          </IconButton>
        </DialogTitle>

        {loading && <Loader />}

        {!loading && !error && (
          <form onSubmit={onSubmitForm}>
            <DialogContent>
              <Controller
                name="orderName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    autoFocus
                    disabled={!canEdit}
                    error={!!errors.orderName}
                    helperText={errors.orderName?.message}
                    fullWidth
                    label="Order Name"
                    margin="dense"
                  />
                )}
              />

              {shouldAllowMaterialTransfer && (
                <>
                  <FormControl
                    component="fieldset"
                    error={!!errors.isMaterialRequestOrTransfer}
                  >
                    <Controller
                      name="isMaterialRequestOrTransfer"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              color="primary"
                              checked={field.value}
                            />
                          }
                          label={materialRequestOrTransferLabel}
                        />
                      )}
                    />

                    {errors.isMaterialRequestOrTransfer && (
                      <FormHelperText error>
                        {errors.isMaterialRequestOrTransfer.message}
                      </FormHelperText>
                    )}
                  </FormControl>

                  {isMaterialRequestOrTransfer && (
                    <RowBox>
                      <Controller
                        defaultValue={defaultSender}
                        name="sender"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={corporateSenders}
                            onChange={(_, value) => {
                              field.onChange(value);
                              trigger("sender");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Sender"
                                margin="dense"
                                fullWidth
                                error={!!errors.sender}
                                helperText={errors.sender?.message}
                              />
                            )}
                            style={{ width: "100%" }}
                          />
                        )}
                      />

                      <Controller
                        name="receiver"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={corporateReceivers}
                            onChange={(_, value) => {
                              field.onChange(value);
                              trigger("receiver");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Receiver"
                                margin="dense"
                                fullWidth
                                error={!!errors.receiver}
                                helperText={errors.receiver?.message}
                              />
                            )}
                            style={{ width: "100%" }}
                          />
                        )}
                      />
                    </RowBox>
                  )}
                </>
              )}

              {shouldShowOrderNumber && (
                <Controller
                  name="orderNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      InputLabelProps={{ shrink: true }}
                      error={!!errors.orderNumber}
                      helperText={errors.orderNumber?.message}
                      fullWidth
                      label="Sales Order / Approved Estimate Number"
                      margin="dense"
                    />
                  )}
                />
              )}

              {dropDifferences?.length > 0 && (
                <div style={{ marginTop: 5 }}>
                  <TableContainer style={{ marginTop: 5 }} component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Screen Name</TableCell>
                          <TableCell>Drop Left</TableCell>
                          <TableCell>Drop Right</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dropDifferences.map((dd) => (
                          <TableRow key={dd.screenId}>
                            <TableCell>{dd.screenName}</TableCell>
                            <TableCell>
                              {dd.measurements.dropLeftDisplay}
                            </TableCell>
                            <TableCell>
                              {dd.measurements.dropRightDisplay}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Message severity="error">
                    Please review the following:
                    <br />
                    These screens will be cut with a{" "}
                    <strong>
                      <u>bottom slope</u>
                    </strong>{" "}
                    to match the difference listed in the measurements below.
                  </Message>
                </div>
              )}
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleCancelSubmit}
                variant="contained"
                color="secondary"
                size="small"
                className={classes.cancelBtn}
              >
                Cancel
              </Button>

              <Button
                className={classes.saveBtn}
                color="primary"
                name="saveOrder"
                size="small"
                type="submit"
                variant="contained"
              >
                {saveButtonLabel}
              </Button>

              <Button
                color="primary"
                name="submitOrder"
                size="small"
                type="submit"
                variant="contained"
              >
                {submitButtonLabel}
              </Button>
            </DialogActions>
          </form>
        )}
      </Dialog>
    </>
  );
}

export { OrderConfirmationDialog };
